<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3>Add a new benchmark profile</h3>
    </div>
</div>
<!--<div class="row" id="validation">-->
<!--            <div class="col-12">-->
<!--                <div class="card wizard-content">-->
<!--                    <div class="card-body">-->
<!--                        &lt;!&ndash;h6 class="card-subtitle"></h6&ndash;&gt;-->
<!--                        <aw-wizard [navBarLayout]="'large-empty-symbols'">-->
<!--                            <aw-wizard-step stepTitle="Benchmark Info" [navigationSymbol]="{ symbol: '1' }">-->
<!--                                <div class="row floating-labels m-t-40">-->
<!--                                    <div class="form-group m-b-40" style="width: 50%;">-->
<!--                                        <mat-label>Benchmark Title</mat-label>-->
<!--                                        <input matInput type="text" [(ngModel)]="title" class="form-control" id="input1">-->
<!--                                        <span class="bar"></span>-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="row">-->
<!--                                    <div class="col-md-6 ">-->
<!--                                        <div class="bt-switch">-->
<!--                                            <div class="switch">-->
<!--                                                <label>-->
<!--                                                    Public-->
<!--                                                    <input type="checkbox" [(ngModel)]="isPrivate" checked="">-->
<!--                                                    <span class="lever"></span>-->
<!--                                                    Private-->
<!--                                                </label>-->

<!--                                            </div>-->
<!--                                            <span *ngIf="isPrivate">Benchmark is Private</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <button type="button" awNextStep class="btn btn-info" style="float: right">Next</button>-->
<!--                            </aw-wizard-step>-->
<!--                            <aw-wizard-step stepTitle="Benchmark Result Upload" [navigationSymbol]="{ symbol: '2' }">-->
<!--                                <div class="row col-md-12">-->
<!--                                    <div style="margin-top: 10px;margin-bottom: 15px;" class="col-md-12">-->
<!--                                        <table style="margin-bottom: 15px;">-->
<!--                                            <tr *ngFor="let benchmark of addedBenchmarks">-->
<!--                                                <td>{{benchmark.type}}</td>-->
<!--                                                <td *ngIf="benchmark.name != 3">{{benchmark.name}}</td>-->
<!--                                                <td *ngIf="benchmark.score == 3">{{benchmark.score}}</td>-->
<!--                                            </tr>-->

<!--                                        </table>-->
<!--                                        <mat-select style="width: 30%;padding-bottom: 10px;min-width: 150px;" name="manager" id="manager" (change)="benchmarkTypeChanged()" [(ngModel)]="currentBenchmarkType" class="form-control" required>-->
<!--                                          <mat-option disabled value="0">Select a benchmark</mat-option>-->
<!--                                          <mat-option *ngFor="let benchmarkType of supportedBenchmarks" [value]="benchmarkType">{{benchmarkType.name}}</mat-option>-->
<!--                                        </mat-select>-->

<!--                                        <div *ngIf="currentBenchmarkType.id == 1" style="padding-bottom: 10px;padding-top: 10px">-->
<!--                                            <input style="min-width: 250px;width: 50%;" type="file" class="form-control required" name="cpuz_html" id="3dmark" (change)="handleFileInput($event.target.files)">-->
<!--                                        </div>-->
<!--                                        <div *ngIf="currentBenchmarkType.id == 3" style="padding-bottom: 10px;padding-top: 10px">-->
<!--                                            <input type="text" placeholder="Cinebench Score" class="form-control required" name="cpuz_html" id="cinebench" [(ngModel)]="currentManualScore">-->

<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <button type="button" awPreviousStep class="btn btn-info">Previous</button>-->
<!--                                <button type="button" class="btn btn-info" style="float: right" (click)="completeBenchmark()">Finish</button>-->
<!--                            </aw-wizard-step>-->
<!--                        </aw-wizard>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
