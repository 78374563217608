<div class="page-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="card col-md-12">
                <div class="card-body">
                    <form class="floating-labels comparison">
                        <div class="form-group m-b-40">
<!--                            <ng-select2 class="-b-10 benchmark-list" [data]="benchmarks" [width]="'100%'" [options]="options"></ng-select2>-->
                        </div>

                        <div class="form-group m-b-40">
                            <input type="text" class="form-control" [(ngModel)]="title" name="compare-title" id="compare-title">
                            <span class="bar"></span>
                            <label for="compare-title" >Benchmark Title</label>
                        </div>
                        <div class="form-group m-b-40">
                            <textarea rows="5" class="form-control" [(ngModel)]="description" name="compare-description" id="compare-description"></textarea>
                            <span class="bar"></span>
                            <label for="compare-description" >Description</label>
                        </div>
                        <button class="btn btn-sm btn-info create-compare" type="submit" (click)="create()">Create Comparison</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
