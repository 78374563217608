import { Component } from '@angular/core';
import {BenchmarkService} from "../benchmark.service";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
    recents = []
    constructor(private benchmarkService: BenchmarkService) {
    }

    ngOnInit() {
        this.benchmarkService.getRecentBenchmarks().subscribe(response => {
            this.recents = response.benchmarks
        })
    }

}
