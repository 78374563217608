<div class="page-wrapper">
    <div class="container-fluid">
        <div class="row page-titles">
            <div class="col-md-5 align-self-center">
                <h3 class="text-themecolor">Leaderboard Results</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card benchmarks">
                    <div class="card-body">
                        <h4 class="card-title">Cinebench</h4>
                        <table class="table well" width="100%" id="cinebench">
                            <thead>
                            <tr class="no-select">
                                <th>User</th>
                                <th>Model</th>
                                <th>Frequency</th>
                                <th>Score</th>
                                <th>Compare</th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
