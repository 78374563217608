/*
 * Maps a benchmark type integer to a string
 *
*/
import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from "@angular/platform-browser";
// TODO: Get this from server
export const BenchmarkTypesMap = {3: {name: 'Cinebench', "type": "CPU"}, 5: {name:'TimeSpy Performance', "type": "GPU"}}

@Pipe({standalone: true, name: 'mapBenchmarkType'})
export class mapBenchmarkType implements PipeTransform {
	transform(input: any): any {
		return BenchmarkTypesMap[input].name
	}
}

@Pipe({standalone: true, name: 'mhzToGhz'})
export class mhzToGhz implements PipeTransform {
	transform(input: any): any {
		return (input / 1000).toFixed(2)
	}
}
@Pipe({standalone: true, name: 'MBToGB'})
export class MBToGB implements PipeTransform {
	transform(input: any): any {
		return input / 1024
	}
}
@Pipe({standalone: true, name: 'money'})
export class moneyPipe implements PipeTransform {
	transform(input: number): any {
		return "$"+input.toFixed(2)
	}
}
@Pipe({standalone: true, name: 'safe'})
    export class SafePipe implements PipeTransform {
    /**
     * Pipe Constructor
     *
     * @param _sanitizer
     */
    // tslint:disable-next-line
    constructor(protected _sanitizer: DomSanitizer) {
    }

    /**
     * Transform
     *
     * @param value: string
     * @param type: string
     */
    transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this._sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this._sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this._sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this._sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this._sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                return this._sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}
