<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header" style="z-index: 99999;cursor:pointer; display: flex;width: unset !important;">
            <span class="navbar-brand" [routerLink]="['/']">
                <!-- Logo icon -->
                <img style="margin-left: 5px;" height="50" width="51" priority ngSrc="https://r2.comparebench.com/assets/logo_button.webp" alt="homepage" class="light-logo"/>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span style="color:white;">CompareBench</span> <span class="badge badge-info">Beta</span>
            </span>
<!--            <span style="padding-left: 10px" [hidden]="true">-->
<!--                <a href="https://www.patreon.com/bePatron?u=3793048" class="patreon" style="color: white"-->
<!--                   target="_blank"><div><img src="assets/patreon.png" width="16px" style="margin: 0 5px 0 0;">Become a Patron!</div></a>-->
<!--            </span>-->
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item"><span class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"><i
                    class="ti-menu"></i></span></li>
                <li class="nav-item hidden-sm-down"><span></span></li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile-module and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <div [hidden]="!loggedIn">
                        <button mat-flat-button (click)="userCardOpen = !userCardOpen" cdkOverlayOrigin
                                #trigger="cdkOverlayOrigin" style="min-width: unset;padding: 0"><img
                            src="/assets/233.png" width="30px" alt="user"/></button>
                    </div>
                    <a class="nav-link dropdown-toggle " *ngIf="!loggedIn" routerLink="/login"
                       aria-haspopup="true" aria-expanded="false">
                        <button class="btn btn-sm btn-info">Log in/Sign Up</button>
                    </a>

                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="userCardOpen"
                        [cdkConnectedOverlayPositions]="positions"
                        [cdkConnectedOverlayOffsetX]="30"
                        (overlayOutsideClick)="userCardOpen = false"
                    >
                        <div style="background-color: #292a2d;border-radius: 5px;min-width: 250px;">
                            <div *ngIf="loggedIn" style="display: flex;    flex-direction: column;    ">
                                <div style="padding: 10px 15px;">
                                    <div style="    display: flex;flex-direction: row;">
                                        <div><img src="/assets/233.png" alt="user" style="width: 70px"></div>
                                        <div style="    padding-left: 10px;">
                                            <h4>{{user.displayName}}</h4>
<!--                                            <p>{{user.username}}</p>-->
                                            <!--                                        <p>Free tier</p>&lt;!&ndash; class="btn btn-rounded btn-primary btn-sm" href="/pricing">Upgrade</a&ndash;&gt;-->
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <hr style="border-top: 1px solid #ffffff1f">
                                </div>
                                    <div class="menuItem"><a style="color:#babcbd" (click)="userCardOpen=false" routerLink="/profile">
                                        <i class="fa-regular fa-user"></i>
                                        My Profile</a></div>
                                    <div class="menuItem"><a (click)="logout()">
                                        <i style="-webkit-transform: scaleX(-1);
  transform: scaleX(-1);" class="fa-solid fa-right-from-bracket"></i>
                                        Logout</a>
                                    </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
    </nav>
</header>
<aside class="left-sidebar" style="width: 100%;position: fixed;max-height: 128px;">
    <div class="scroll-sidebar" style="    height: 58px;
    max-width: 1280px;
        padding-left: 15px;
    margin: 0 auto;">
        <nav class="sidebar-nav">
            <div id="sidebarnav" style="display: flex; flex-direction: row;height: 58px">

                <a mat-flat-button routerLink="/dashboard" aria-expanded="false">
                    <i class="fa-solid fa-gauge fa-xl"></i>
                    <span>Dashboard</span>
                </a>
                <button mat-flat-button [matMenuTriggerFor]="menu" class="dropdown-arrow"
                        aria-label="Example icon-button with a menu">
                    <i class="fa-solid fa-microchip fa-xl"></i>
                    Products
                </button>
                <mat-menu #menu="matMenu" class="nestedmenu">
                    <button mat-menu-item routerLink="/products/cpu">
                        <i class="fa-solid fa-microchip"></i>
                        <span>CPUs</span>
                    </button>
                    <button mat-menu-item routerLink="/products/gpu">
                        <i class="fa-solid fa-microchip"></i>
                        <span>GPUs</span>
                    </button>

                </mat-menu>
                <a mat-flat-button routerLink="/roadmap" *ngIf="loggedIn" aria-expanded="false">
                    <i class="fa-solid fa-map fa-xl"></i>
                    <span class="hide-menu">Roadmap</span>
                </a>
                <a mat-flat-button routerLink="/faq" aria-expanded="false">
                    <i class="fa-solid fa-question fa-xl"></i>
                    <span>FAQ</span>
                    <!--                        <span style="font-size: 24px">?</span>-->
                    <!--                        <span>FAQ</span>-->
                </a>
                <a mat-flat-button routerLink="/yardstick" aria-expanded="false">
                    <i class="fa-solid fa-ruler-horizontal fa-xl"></i>
                    <span>Yardstick</span>
                    <!--                        <span style="font-size: 24px">?</span>-->
                    <!--                        <span>FAQ</span>-->
                </a>
                <!--                <li>-->
                <!--                    <a class="has-arrow waves-effect waves-dark" aria-expanded="false">-->
                <!--                        <i class="mdi mdi-gauge"></i>-->
                <!--                        <span class="hide-menu">Products</span>-->
                <!--                    </a>-->
                <!--                    <ul aria-expanded="false" class="collapse waves-effect waves-dark" style="background-color: #292a2d;">-->
                <!--                        <li style="justify-content: center;display: flex;"><span [routerLink]="['/products/cpu']" style="display: flex;flex-direction: column;"><i class="mdi mdi-chip" style="font-size: 35px"></i>CPUs</span></li>-->
                <!--                    </ul>-->
                <!--                </li>-->

                <!--li> <a class="has-arrow waves-effect waves-dark" href="/news" aria-expanded="false"><i class="mdi mdi-newspaper"></i><span class="hide-menu">News</span></a>

                </li-->
                <!--                <li> <a class="has-arrow waves-effect waves-dark" routerLink="/results" aria-expanded="false"><i class="mdi mdi-view-list"></i><span class="hide-menu">Browse Results</span></a>-->

                <!--                </li>-->

            </div>
        </nav>
    </div>
</aside>
