import {Injectable, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {BuildProfile} from "./interfaces/benchmark";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class CompareBarService implements OnInit{
    private _benchmarks: BuildProfile[] = new Array<BuildProfile>()
    private benchmarks = new Subject<BuildProfile[]>();
    constructor(private cookieService: CookieService) {

    }
    ngOnInit(){

    }
    getBenchmarks(): Observable<BuildProfile[]> {
        return this.benchmarks.asObservable();
    }
    clearAll(){
        this._benchmarks = []
        this.benchmarks.next(this._benchmarks);
        this.cookieService.set("compareBar", btoa(JSON.stringify(this._benchmarks)), 1)
    }
    addBenchmark(benchmark: BuildProfile) {
        this._benchmarks.push(benchmark)
        this.benchmarks.next(this._benchmarks);
        this.cookieService.set("compareBar", btoa(JSON.stringify(this._benchmarks)), 1)
    }
}
