<!--<div class="preloader" [hidden]="!initLoading">-->
<!--    <div class="loader">-->
<!--        <div class="loader__figure"></div>-->
<!--        <p class="loader__label">CompareBench</p>-->
<!--    </div>-->
<!--</div>-->
<div id="main-wrapper">
    <app-navigation></app-navigation>
    <div class="page-wrapper">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer></app-footer>
</div>
<app-compare-bar></app-compare-bar>


