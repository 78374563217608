import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BenchmarkService} from "../benchmark.service";
import {CompareBarService} from "../compare-bar.service";

@Component({
  selector: 'app-comparison-creation',
  templateUrl: './comparison-creation.component.html',
  styleUrls: ['./comparison-creation.component.css']
})
export class ComparisonCreationComponent implements OnInit {
    options = {
        placeholder: 'Choose Benchmark Profiles',
        multiple: true
    };
    title;
    description;
    benchmarks;

    constructor(private route: ActivatedRoute, private benchmarkService: BenchmarkService, private router: Router, private compareBarService: CompareBarService) {
    }
    create(){
        this.benchmarkService.createComparison(this.benchmarks, this.title, this.description).subscribe(response=>{
            this.compareBarService.clearAll()
            this.router.navigate(['/compare', response["compare"]['compare_id']])
        })
    }

    ngOnInit() {
        this.benchmarkService.getBenchmarks().subscribe((response) => {
            this.benchmarks = [];
            for(let i=0;i<response['benchmark-creation-profile'].length;i++){
                let benchmark = {
                    id: response['benchmark-creation-profile'][i]['id'],
                    text:response['benchmark-creation-profile'][i]['title']
                };
                this.benchmarks.push(benchmark)
            }
        })
    }


}
