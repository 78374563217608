<mat-toolbar>
	<mat-toolbar-row>
		<h2>Upload Photos</h2>
		<span flex></span>
		<button mat-button class="" (click)="close()">
			<span class="material-icons">close</span>
		</button>
	</mat-toolbar-row>
</mat-toolbar>

<div mat-dialog-content>
	<div style="margin:20px">
		<div style="margin: 10px;">
            <input type="file" (change)="files = $any($event.target).files" accept="image/*" required>
        </div>
	</div>
</div>

<div mat-dialog-actions layout="row">

	<button class="btn btn-sm btn-info" (click)="upload()" >
		<span>Upload</span>
	</button>
	<button class="btn btn-sm" (click)="close()">
		<span>Cancel</span>
	</button>

</div>
