import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "./user.service";
import {User} from "./interfaces/response";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private userService: UserService) {

    }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log(this.userService.user)
      return this.userService.getAuth().pipe(
          map(e => {
              if (e) {
                  this.userService.user = new User(e)
                  try {
                      if (this.userService.user.uid == 37) {
                          return true
                      } else {
                          return false
                      }
                  }
                  catch (e) {
                      return false
                  }
              }

          }))
          }
  }
