<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Create Product</h3>
    </div>
</div>
    <div class="container-fluid">
        <div class="row">
            <div class="card col-md-12">
                <div class="card-body">
                    <form class="floating-labels comparison">
                        <div class="form-group m-b-40">
                            <input type="text" class="form-control" [(ngModel)]="search" name="compare-title" id="compare-title">
                            <span class="bar"></span>
                            <label for="compare-title" >Search Query</label>
                        </div>
                        <button mat-button (click)="fetchSpecs()">Fetch</button>
                        <div class="form-group m-b-40">
                            <textarea rows="5" class="form-control" [(ngModel)]="description" name="compare-description" id="compare-description"></textarea>
                            <span class="bar"></span>
                            <label for="compare-description" >Description</label>
                        </div>
                        <button class="btn btn-sm btn-info create-compare" type="submit" (click)="create()">Create Comparison</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
