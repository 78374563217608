 <div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Frequently Asked Questions</h3>
    </div>
</div>
<div class="row">
    <mat-card>
      <mat-card-title>What is this?</mat-card-title>
      <mat-card-content>
        <p>CompareBench is a platform for capturing user hardware details and benchmark results. Once we have those two things, there's quite a lot you can do. You can compare your benchmark results with friends in easy-to-read graphs or create Revisions* when you upgrade your PC to see how it's improved.</p>
      </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
      <mat-card-title>Is this different or better than UserBenchmark?</mat-card-title>
      <mat-card-content>
        <p>
    We'd like to think so. One of the problems with sites like UserBenchmark is it displays performance data based on regular users submitting benchmark results, which can inherently be flawed. If enough users have a bunch of crap on their PC, it can skew the average performance quite a bit from where it should be.
        </p>
          <p>
              While we will store benchmarks you created yourself, we'll never show your benchmark data in aggregate on product pages. Any performance data you see on those pages will come from verified sources.
          </p>
      </mat-card-content>
    </mat-card>
    <br>

    <mat-card>
      <mat-card-title>Is this another hardware review outlet?</mat-card-title>
      <mat-card-content>
        <p>
            Not exactly. We do intend to publish some benchmarks along with clear methodology, but that's more a means to an end. We want to understand what works and what doesn't from a process perspective of how an outlet might publish benchmarks on our platform.
        </p>
          <p>That said, we reserve the right to continue publishing our own benchmarks too :P</p>

      </mat-card-content>
    </mat-card>
    <br>

    <mat-card>
      <mat-card-title>What's a "Verified" benchmark?</mat-card-title>
      <mat-card-content>
        <p>
           Our intention for "verified" or "approved" benchmarks that you'll see on product pages means a few things.
        </p>
          <ul>
              <li>
                  That they should be 100% replicable by the end user. Meaning we won't show you benchmarks that require some setup to get in the right spot in a game, the right time of day etc. This means you'll only really see built-in-game benchmarks or otherwise very easy to replicate benchmarks. We want to leave the more in-depth analysis to the likes of GamersNexus or DigitalFoundry.
              </li>
              <li>
                  They should come from a trusted source. Usually an outlet like the ones mentioned above.
              </li>
          </ul>

      </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
      <mat-card-title>How does one publish a "verified" benchmark?</mat-card-title>
      <mat-card-content>
        <p>
           For now these are going to be reserved to benchmarks we publish ourselves. Eventually these would include benchmarks published from trusted 3rd party sources. Generally speaking a regular user would not have the ability to publish a verified benchmark, nor submit one to be verified.
        </p>
      </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
      <mat-card-title>Why would an existing review outlet with their own audience publish their benchmarks here?</mat-card-title>
      <mat-card-content>
        <p>
           Ultimately we want to allow them to publish their benchmarks on our platform if they want to, with some extra benefits if they decide to do so. For smaller outlets, this could mean better exposure. For larger outlets that don't need the exposure, we have some ideas we're thinking through for them as well.
        </p>
          <p>We recognize that a significant portion of those outlets revenue comes from ads, and we don't want to interrupt that by pulling eyeballs off of their videos. We do think our platform is a more digestible, useful, and longer-lasting form of publishing that kind of data so we're coming up with ways to incentivize those outlets to publish here. This is still in the works so details are TBD. If you're an outlet and would like to reach out, please send a message over to jeremy@comparebench.com</p>
      </mat-card-content>
    </mat-card>
    <br>

    <mat-card>
      <mat-card-title>What's Yardstick?</mat-card-title>
      <mat-card-content>
        <p>
            Yardstick is a desktop application we're building that facilitates all of this. It's what you'll use to upload your computer's hardware details and manage Revisions*. We're still working on it but some core features are:
        </p>
        <ul>
            <li>Upload and manage benchmark results for your Build, such as uploading 3DMark results</li>
            <li>Create Build Revisions</li>
            <li>View and manage all Builds on your account (if you have multiple PC's)</li>
        </ul>

      </mat-card-content>
    </mat-card>
    <p>* We're still working on Revisions and hope to have it ready soon.</p>
</div>

