import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BenchmarkService} from "../benchmark.service";

@Component({
  selector: 'app-profile-photo-upload',
  templateUrl: './profile-photo-upload.component.html',
  styleUrls: ['./profile-photo-upload.component.css']
})
export class ProfilePhotoUploadComponent implements OnInit {
    files = []
	loading = false;
	@ViewChild('uploadElement')
	uploadElement: ElementRef|any;
    constructor(public dialogRef: MatDialogRef<ProfilePhotoUploadComponent>,
                private benchmarkService: BenchmarkService,
                @Inject(MAT_DIALOG_DATA) public data: any,) {
    }

    ngOnInit(): void {
    }

    upload() {
        console.log("upload")
		this.loading = true;

		const formData = new FormData();
		for(let f=0;f<this.files.length;f++){
			formData.append('files', this.files[f]);
		}

		formData.append('profileId', this.data.rid);
		this.benchmarkService.uploadPhoto(formData).subscribe((response)=>{
			this.loading = false;
			this.dialogRef.close(true);
		})
    }

    close() {
        this.dialogRef.close(false);
    }

}
