import { Component, OnInit } from '@angular/core';
import {UserService} from "../user.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-yardstick-landing',
  templateUrl: './yardstick-landing.component.html',
  styleUrls: ['./yardstick-landing.component.css']
})
export class YardstickLandingComponent implements OnInit {

    constructor(private UserService: UserService, private title: Title, private meta: Meta) {
    }

    email: string = ''
    success: boolean = false

    ngOnInit(): void {
        this.title.setTitle('CompareBench Yardstick');
        var description = "Use Yardstick to keep track of your computer's performance when you make changes. New GPU installed? Run another benchmark and compare the difference."
        this.meta.updateTag({ name: 'description', content: description })
        this.meta.updateTag({ property: 'og:description', content: description })
        this.meta.updateTag({ property: 'twitter:description', content: description })
        this.meta.updateTag({ property: 'og:image', content: 'https://comparebench.com/assets/yardstick-app1.png' })
        this.meta.updateTag({ property: 'og:title', content: "CompareBench Yardstick" })
        this.meta.updateTag({ property: 'og:url', content: "https://comparebench.com/yardstick" })
        this.meta.updateTag({ property: 'twitter:url', content: "https://comparebench.com/yardstick" })
        this.meta.updateTag({ property: 'twitter:title', content: "CompareBench Yardstick" })
        this.meta.updateTag({ property: 'twitter:image', content: 'https://comparebench.com/assets/yardstick-app1.png' })
    }

    captureEmail() {
        this.UserService.captureEmail(this.email).subscribe(response=>{
            if(response){
                this.success = true
            }
        })
    }

}
