import { Component, OnInit } from '@angular/core';
import {ProductService} from '../product.service';

@Component({
  selector: 'app-admin-create-product',
  templateUrl: './admin-create-product.component.html',
  styleUrls: ['./admin-create-product.component.css']
})
export class AdminCreateProductComponent implements OnInit {
    search;
    description;
  constructor(private ProductService: ProductService) { }

  ngOnInit(): void {
  }
  create(){

  }
  fetchSpecs(){
      this.ProductService.getGPTSpecs(this.search).subscribe(response=>{
          console.log(response)
      })
  }

}
