
<div class="row">
    <div class="col-lg-12">
        <span class="centered" style="text-align: center;display: flex;justify-content: center;"><img src="https://r2.comparebench.com/assets/logo_footer.webp"></span>
        <h1 style="font-size: 30px;text-align: center;font-family: 'Roboto,Helvetica Neue,sans-serif;';font-weight: 300;color: #babcbd;">Yardstick</h1>
        <hr>
    </div>

    <hr class="hr-landing">
    <div class="col-lg-6" style="font-family: 'Raleway'">
        <p>
            We're developing a new desktop application that gathers your system's hardware details so you can easily upload them to CompareBench.</p>
        <p>
            Use Yardstick to keep track of your computer's performance when you make changes. New GPU installed? Run another benchmark and compare the difference. </p>
        <hr class="hr-landing">
        <p>
            It's still being developed and we have a lot of big ideas for Yardstick. Enter your email below to get notified when it's ready!</p>
        <div *ngIf="!success" style="display: flex;flex-wrap: nowrap;justify-content: space-between;">
            <input type="text" placeholder="Email Address" class="form-control required" [(ngModel)]="email">
            <button style="margin-left: 10px" mat-raised-button class="" [disabled]="email == ''" (click)="captureEmail()">
                Submit
            </button>
        </div>
        <div *ngIf="success" style="display: flex;justify-content: center;align-items: center;flex-direction: column">
            <div>Success! You'll hear from us soon.</div>
            <div>
                In the meantime, feel free to <a href="https://discord.gg/V3nRgYA">join our Discord!</a>
            </div>
        </div>
    </div><!-- col-lg-6 -->

    <div class="col-lg-6">
        <img class="img-thumbnail" src="/assets/yardstick-app1.png">
    </div>

</div><!-- row -->
