
export interface IResponse {
    bench_results: any;
    comparisons: any;
    results_count: any;
    compare_count: any;
    benchmarks:any;
	any
}
export interface ILoginResponse {
	status: any
    message: any
    user: User
}

export class User {
    displayName: string
    uid: number
    constructor(jsonData){
        this.displayName = jsonData.display_name
        this.uid = jsonData.uid
    }
}
