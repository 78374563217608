<div>
    <div class="container-fluid">
        <div class="row page-titles" style="justify-content: center;">
            <div class="col-md-4 align-self-center">
                <h3>Create an Account</h3>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="card col-md-4">
                <div class="card-body">
                    <form [formGroup]="signupForm" style="text-align: center">
                        <h6 class="error" [hidden]="!errorMessage">{{errorMessage}}</h6>
                        <mat-form-field>
                          <mat-label>Email</mat-label>
                          <input matInput type='text' formControlName="email">
                        </mat-form-field>
                        <br>
                        <mat-form-field>
                          <mat-label>Password</mat-label>
                          <input matInput type='password' formControlName="password">
                        </mat-form-field>
                        <br>
                        <button mat-raised-button name="form.submitted" (click)="signup()" style="margin: 10px 0 5px 0;">Sign Up</button>
                        <br>
                    </form>
                    <hr>
                    <div style="display: flex;justify-content: center;flex-direction: column;text-align: center;">
                        <span>Or Login with</span><br>
                        <button mat-button (click)="discord()"><img src="/assets/discord_logo.png" style="width: 50px"></button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
